
import {defineComponent, ref} from 'vue';
import {useCreatingForm} from '@/core/hooks/use-creating-form';
import {useRouterCustom} from '@/core/hooks/use-router-custom';
import {IChungLoaiToaXeResponseBase} from '@/core/interfaces/ApiResponses';
import {swalNotification} from '@/core/helpers/utils';
import { setCurrentPageTitle } from '../../../../core/helpers/breadcrumb';

import ToaXeService from '@/core/services/ToaXe.service';
import ChungLoaiService from '@/core/services/ChungLoai.service';
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import { useButtonCustom } from '../../../../core/hooks/use-button-custom';

export default defineComponent({
	name: 'toa-xe-form',

	components: { ButtonCustom },

	setup() {
		const { id, formRef, loading, isCreating } = useCreatingForm();
		const { goBack, replace } = useRouterCustom();
		const { ButtonTypeColors, ButtonsType } = useButtonCustom();
		const toaXeForm = ref({
			ten_toa_xe: '',
			ma_toa_xe: '',
			ghi_chu: '',
			so_hieu: '',
			id_chung_loai: 0,
		});

		return {
			toaXeForm,
			toaXeId: id,
			formRef,
			loading,
			isCreating,
			goBack, replace,
			ButtonTypeColors, ButtonsType,
		}
	},

	data() {
		return {
			rules: {
				ten_toa_xe: [
					{
						required: true,
						message: "Mời bạn nhập tên toa xe",
						trigger: "blur",
					}
				],
				ma_toa_xe: [
					{
						required: true,
						message: "Mời bạn nhập mã toa xe",
						trigger: "blur",
					}
				],
				so_hieu: [
					{
						required: true,
						message: "Mời bạn nhập số hiệu",
						trigger: "blur",
					}
				],
			},
			chungLoaiItems: [] as IChungLoaiToaXeResponseBase[],
		}
	},

	async mounted() {
		setCurrentPageTitle('Toa xe');
		try {
			if (!this.isCreating) {
				const { data: { data: res } } = await ToaXeService.get(this.toaXeId);
				this.toaXeForm.ma_toa_xe = res.ma_toa_xe;
				this.toaXeForm.ten_toa_xe = res.ten_toa_xe;
				this.toaXeForm.ghi_chu = res.ghi_chu;
				this.toaXeForm.so_hieu = res.so_hieu;
				this.toaXeForm.id_chung_loai = res.id_chung_loai;
			}

			const { data: { data: { data: chungLoaiItems } } } = await ChungLoaiService.list();
			this.chungLoaiItems = chungLoaiItems;
		} catch (err) {
			await swalNotification(
				'Xin lỗi, có vẻ có lỗi xảy ra khi lấy thông tin',
				'error',
			);
		}
	},

	methods: {
		clear() {
			this.toaXeForm.ten_toa_xe = '';
			this.toaXeForm.so_hieu = '';
			this.toaXeForm.ghi_chu = '';
			this.toaXeForm.id_chung_loai = 0;
			if (this.isCreating) {
				this.toaXeForm.ma_toa_xe = '';
			}
		},

		async submit() {
			if (!this.formRef) {
				return;
			}

			this.formRef.validate(async (valid) => {
				if (valid) {
					this.loading = false;

					try {
						if (this.isCreating) {
							const { data: { data: { id } } } = await ToaXeService.create(
								this.toaXeForm.ten_toa_xe,
								this.toaXeForm.ma_toa_xe,
								this.toaXeForm.so_hieu,
								this.toaXeForm.ghi_chu,
								this.toaXeForm.id_chung_loai,
							)
							this.toaXeId = id;
						} else {
							await ToaXeService.update(
								this.toaXeId,
								this.toaXeForm.ten_toa_xe,
								this.toaXeForm.ma_toa_xe,
								this.toaXeForm.so_hieu,
								this.toaXeForm.ghi_chu,
								this.toaXeForm.id_chung_loai,
							)
						}

						await swalNotification(
							`${this.isCreating ? 'Tạo mới' : 'Chỉnh sửa'} danh mục toa xe thành công`,
							'success',
						);

						await this.replace(`/quan-ly/toa-xe/${this.toaXeId}/chi-tiet`)
					} catch {
						await swalNotification(
							'Xin lỗi, có vẻ có lỗi xảy ra',
							'error',
						);
					}
				} else {
					return false;
				}
			})
		}
	}
})
