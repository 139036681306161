import {
  ApiResponse, IToaXeResponseBase, PaginationResponseBase
} from '@/core/interfaces/ApiResponses';
import { apiServiceInstance } from "@/core/helpers/utils";

export default class ChungLoaiService {
  static list(): Promise<{
    data: ApiResponse<PaginationResponseBase<any>>
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      '/api/v1/chung-loai-toa-xe/danh-sach'
    )
  }
}
